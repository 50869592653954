import React, { useState } from 'react';
import classnames from 'classnames';
import { PropsWithTestId } from '../../../../../../types';
import {
  VideoModuleType,
  VideoModulePositions,
  VideoModuleWidth,
  VideoModuleDisplayOption,
} from './types';
import { Paragraph, ModuleHeader } from '../../components';
import { HubModuleWrapper } from '../../HubModuleWrapper';
import Preview from './Preview';
import VideoModal from '../../../VideoModal';
import VideoPlayer from '../../../VideoPlayer';
import { CallToAction } from '../../../CallToActionGroup';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './VideoModule.module.scss';
import {
  sourceSettings,
  VideoModuleSources,
} from '../../../VideoPlayer/constants';
import {
  isPrivateVideo,
  parseStreamlyUrl,
} from '../../../../../../utils/streamlyVideos';

export interface VideoModuleProps {
  module: VideoModuleType;
}

export default function VideoModule(props: PropsWithTestId<VideoModuleProps>) {
  const {
    module: {
      '@type': type,
      title,
      titleSize,
      text,
      videoPosition,
      videoDisplayOption,
      link,
      videoId,
      videoSource,
      videoUrl,
      videoWidth,
      posterImage,
      visualStyle,
      elementId,
    },
    testId,
  } = props;
  const history = useHistory();
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [videoPlay, setVideoPlay] = useState<boolean>(false);
  const { search } = useLocation();
  const { accessType, thumbnailUrl, sourceUrl } = parseStreamlyUrl(videoUrl);
  const pageWidth = videoWidth === VideoModuleWidth.PAGE;

  // videoWidth === VideoModuleWidth.PAGE
  const pageWidthPreviewClassName = classnames(styles.playerCenterWrapper, {
    [styles.playerPageWidthCenterWrapper]: pageWidth,
  });
  const pageWidthClassName = classnames({
    [styles.pageWidthText]: pageWidth,
  });
  const paragraphTopClassName = classnames(
    styles.paragraphTop,
    pageWidthClassName,
  );

  const toggleModal = () => {
    // Play the video instead of preview image
    if (videoDisplayOption === VideoModuleDisplayOption.PLAY_IN_PLACE) {
      setVideoPlay(true);
      return;
    }

    // Open new window that contain the video iframe
    if (videoDisplayOption === VideoModuleDisplayOption.NEW_WINDOW) {
      let fulScreenUrl;

      // Streamly doesn't support fullscreen mode, so need o redirect on the video page
      if (videoSource === VideoModuleSources.STREAMLY_VIDEO) {
        fulScreenUrl = sourceUrl;
      } else {
        fulScreenUrl = `${sourceSettings[videoSource].path}${videoId}`;
      }

      window.open(fulScreenUrl, '_blank');
      return;
    }

    // Only for Streamly videos
    if (isPrivateVideo(accessType)) {
      window.open(sourceUrl, '_blank');
      return;
    }

    // Default behaviour. videoDisplayOption === VideoModuleDisplayOption.OVERLAY
    if (modalOpened) {
      history.push(`${search}#`);
    } else {
      history.push(`${search}#video-${videoId}`);
    }
    setModalOpened((prev) => !prev);
  };

  const buildPreviewSrc = () => {
    if (posterImage) return posterImage.path;
    if (thumbnailUrl && videoSource === VideoModuleSources.STREAMLY_VIDEO) {
      return thumbnailUrl;
    }
    return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  };

  const renderPreviewSection = () => {
    if (videoPlay) {
      return (
        <VideoPlayer
          source={videoSource}
          id={videoId}
          title={title}
          streamlyUrl={sourceUrl}
          className={styles.videoPlayer}
        />
      );
    }

    return <Preview src={buildPreviewSrc()} onClick={toggleModal} />;
  };

  return (
    <HubModuleWrapper
      type={type}
      theme={visualStyle}
      elementId={elementId}
      testId={testId}
    >
      <div className="container">
        <div className="row">
          {videoPosition === VideoModulePositions.LEFT && (
            <>
              <div className="col-xs-12 col-lg-6">
                <div className={styles.playerWrapper}>
                  {renderPreviewSection()}
                </div>
              </div>
              <div className="col-xs-12 col-lg-6">
                <div className={styles.leftPadding}>
                  <ModuleHeader
                    title={title}
                    titleSize={titleSize}
                    text={text}
                    lineAfterHeading={!pageWidth}
                    link={link}
                  />
                </div>
              </div>
            </>
          )}
          {videoPosition === VideoModulePositions.RIGHT && (
            <>
              <div className="col-xs-12 col-lg-6">
                <div className={styles.rightPadding}>
                  <ModuleHeader
                    title={title}
                    titleSize={titleSize}
                    text={text}
                    lineAfterHeading={!pageWidth}
                    link={link}
                  />
                </div>
              </div>
              <div className="col-xs-12 col-lg-6">
                <div className={styles.playerWrapper}>
                  {renderPreviewSection()}
                </div>
              </div>
            </>
          )}
          {videoPosition === VideoModulePositions.TOP && (
            <div className="col-xs-12">
              <ModuleHeader
                title={title}
                titleSize={titleSize}
                lineAfterHeading={!pageWidth}
                className={pageWidthClassName}
              />
              <div className={pageWidthPreviewClassName}>
                {renderPreviewSection()}
              </div>
              {text && (
                <Paragraph text={text} className={paragraphTopClassName} />
              )}
              {link?.type && (
                <CallToAction
                  link={link}
                  variant="contained"
                  align="center"
                  className={styles.ctaButton}
                  data-testid="hub-module-link"
                />
              )}
            </div>
          )}
          {videoPosition === VideoModulePositions.BOTTOM && (
            <div className="col-xs-12">
              <ModuleHeader
                title={title}
                titleSize={titleSize}
                text={text}
                lineAfterHeading={!pageWidth}
                className={pageWidthClassName}
              />
              <div className={pageWidthPreviewClassName}>
                {renderPreviewSection()}
              </div>
              {link?.type && (
                <CallToAction
                  link={link}
                  variant="contained"
                  align="center"
                  className={styles.ctaButton}
                  data-testid="hub-module-link"
                />
              )}
            </div>
          )}
        </div>
      </div>
      {modalOpened && (
        <VideoModal open={modalOpened} onClose={toggleModal}>
          <VideoPlayer
            source={videoSource}
            id={videoId}
            title={title}
            streamlyUrl={sourceUrl}
          />
        </VideoModal>
      )}
    </HubModuleWrapper>
  );
}

VideoModule.defaultProps = {
  testId: 'hub-video-module',
};
